import React, { useEffect, useState } from 'react';
import store from '../../redux-store/store';
import { useParams } from 'react-router-dom';
import { setPageTitle, setActivityIndicator } from '../../redux-store/slices/app-slice';
import Settings from '../../components/settings/settings';
import { Setting } from '../../components/settings/settings.props';
import { UserService } from '../../fetch-services/user.service';
import { UserInfo } from '../../fetch-services/user.service.types';
import { Role } from '../../common/enums';
import { getRoleDisplayName, getUserDisplayName } from '../../common/utils';
import '../containers.scss';

const EducatorProfile = () => {
  const { id } = useParams();
  const [educatorSettings, setEducatorSettings] = useState<Setting[]>([
    { setting: 'Name', values: ['-'] },
    { setting: 'Username/Email', values: ['-'] },
    { setting: 'Associated Schools', values: ['-'] },
    { setting: 'Type', values: ['-'] },
    { setting: 'External ID', values: ['-'] }
  ]);

  const updateEducatorProfile = (response: UserInfo) => {
    let educatorName = getUserDisplayName(response);
    store.dispatch(setPageTitle(educatorName));
    const orgSettingTitle = response.role === Role.DistrictAdmin ? 'Associated Districts' : 'Associated Schools';
    setEducatorSettings([
      { setting: 'Name', values: [educatorName] },
      { setting: 'Username/Email', values: [response.username || '-'] },
      { setting: orgSettingTitle, values: response.organizations?.map(org => org.name) || ['-'] },
      { setting: 'Type', values: [getRoleDisplayName(response.role) || '-'] },
      { setting: 'External ID', values: [response.districtAssignedId || '-'] }
    ]);
  };
      
  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await UserService.getUserInfo(id ?? '');
      if (response) {
        updateEducatorProfile(response);
      }
      store.dispatch(setActivityIndicator(false));
    }
    
    if (id) {
      fetchData();
    } else {
      store.dispatch(setPageTitle('Educator Profile'));
    }
  }, [id]);

  return (
    <div className='asmt-container'>
      <Settings settings={educatorSettings} title='Educator Profile' />
    </div>
  );
};

export default EducatorProfile;