import React, { useEffect, useState } from 'react';
import store from '../../redux-store/store';
import { useParams } from 'react-router-dom';
import { setPageTitle, setActivityIndicator } from '../../redux-store/slices/app-slice';
import { Tabs } from '@wne/mpng-components';
import ClassCards from '../../components/class-cards/class-cards';
import Settings from '../../components/settings/settings';
import { UserService } from '../../fetch-services/user.service';
import { Setting } from '../../components/settings/settings.props';
import { Student } from '../../fetch-services/user.service.types';
import { getUserDisplayName } from '../../common/utils';
import { Role } from '../../common/enums';
import '../containers.scss';

const StudentProfile = () => {
  const { id } = useParams();
  const [studentSettings, setStudentSettings] = useState<Setting[]>([
    { setting: 'Name', values: ['-'] },
    { setting: 'Username', values: ['-'] },
    { setting: 'External ID', values: ['-'] },
    { setting: 'School Name', values: ['-'] },
    { setting: 'Grade Level', values: ['-'] }
  ]);

  const updateStudentProfile = (response: Student) => {
    let studentName = getUserDisplayName(response);
    store.dispatch(setPageTitle(studentName));
    setStudentSettings([
      { setting: 'Name', values: [studentName] },
      { setting: 'Username', values: [response.loginName || '-'] },
      { setting: 'External ID', values: [response.districtAssignedId?.toString() || '-'] },
      { setting: 'School Name', values: [response.schoolName || '-'] },
      { setting: 'Grade Level', values: [response.gradeLevelName || '-'] }
    ]);
  };

  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await UserService.getStudent(id ?? '');
      if (response) {
        updateStudentProfile(response);
      }
      store.dispatch(setActivityIndicator(false));
    }
    
    if (id) {
      fetchData();
    } else {
      store.dispatch(setPageTitle('Student Profile'));
    }
  }, [id]);

  const tabsProps = {
    className: 'asmt-container',
    defaultActiveKey: 'profile',
    mountOnEnter: true,
    items: [
      {
        title: 'Profile',
        eventKey: 'profile',
        content: <Settings settings={studentSettings} />
      },
      {
        title: 'Classes',
        eventKey: 'classes',
        content: (
          <ClassCards 
            displayFor={Role.Student}
            studentGuid={id}
            studentName={studentSettings[0].values[0]}
          />
        )
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default StudentProfile;
