import React, { useEffect } from 'react';
import store from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import OrgSettings from '../../components/org-settings/org-settings';
import '../containers.scss';

const OrgSettingsContainer = () => {
  useEffect(() => {
    store.dispatch(setPageTitle('Settings'));
  }, []);

  return (
    <div className='asmt-container'>
      <OrgSettings />
    </div>
  );
};

export default OrgSettingsContainer;
