import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setCurrentOrg, setMainOrg } from '../../redux-store/slices/user-slice';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import DistrictInstitutions from '../../components/district-institutions/district-institutions';
import { Organization } from '../../fetch-services/org.service.types';
import { PagedNavTable, ProductEnum } from '@wne/mpng-components';
import { OrgGroupLevel, SidebarRoute } from '../../common/enums';
import { CurrentOrgGuidSessionStorageKey, MainOrgGuidSessionStorageKey } from '../../common/consts';
import '../containers.scss';
import './institutions.scss';

const Institutions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo, mainOrg, currentOrg } = useSelector((state: RootState) => state.user);
  const [showDistrictInstitutions, setShowDistrictInstitutions] = React.useState<boolean>(false);
  const [showUserOrgs, setShowUserOrgs] = React.useState<boolean>(false);
  const redirectPath = searchParams.get('link');

  useEffect(() => {
    store.dispatch(setPageTitle('Institutions'));
    if (userInfo?.organizations) {
      if ((userInfo.organizations.length > 1 && !mainOrg) || !redirectPath) {
        // show user orgs initially if mainOrg selection is needed or no redirect path (e.g. Institutions sidebar tab)
        setShowUserOrgs(true);
      } else if (currentOrg && currentOrg?.orgType.toLowerCase() === OrgGroupLevel.District &&
                 redirectPath === SidebarRoute.Formatives) {       
        // show district orgs initially for Formatives page redirect from a district current org if mainOrg is already set
        setShowDistrictInstitutions(true);
      }
    }
  }, [userInfo, mainOrg, currentOrg]);

  const handleOrgClick = (org: Organization) => {
    // Only set main org if selecting from user orgs
    if (showUserOrgs && !showDistrictInstitutions) {
      store.dispatch(setMainOrg(org));
      // store in session storage to preserve in case of page refresh
      sessionStorage.setItem(MainOrgGuidSessionStorageKey, org.orgGuid);
    }
    // Always set the current org
    store.dispatch(setCurrentOrg(org));
    sessionStorage.setItem(CurrentOrgGuidSessionStorageKey, JSON.stringify(org));

    if (redirectPath) {
      // If redirecting to Formatives, drill down to school before navigating
      if (org.orgType?.toLowerCase() === OrgGroupLevel.District && redirectPath === SidebarRoute.Formatives) {
        setShowDistrictInstitutions(true);
        setShowUserOrgs(false);
      } else {
        navigate(redirectPath);
      }
    }
  };

  let columnDetails: any[] = [
    {
      name: 'Institution Name',
      isSorted: false,
      isLink: true,
      onLinkSelect: (data: any) => handleOrgClick(data),
      getColumnData: (data: any) => data.name,
      alignment: 'left'
    }
  ];

  return (
    <div className='asmt-container'>
      {showUserOrgs &&
      <div className='user-institutions-table-container'>
        <PagedNavTable
          columnDetails={columnDetails}
          tableData={userInfo?.organizations ?? []}
          tableHeight={'100%'}
          minTableHeight='190px'
        />
      </div>}
      {showDistrictInstitutions &&
      <DistrictInstitutions
        orgCallback={handleOrgClick}
        //Institutions page only loads districtInstitutions for formatives redirect
        //limit to child orgs with products that have Formatives access 
        filterProducts={[ProductEnum.IPA_ELA, ProductEnum.IPA_Math]}
      />}
    </div>
  );
};

export default Institutions;
