import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import {
  SearchSubmit,
  PagedNavTable,
  MpngExportReport,
  HelpMessage,
  HelpMessageEnum,
  ProductEnum
} from '@wne/mpng-components';
import {
  Paged,
  Student,
  StudentSearchByDistrictRequest,
  StudentSearchBySchoolRequest
} from '../../fetch-services/user.service.types';
import { UserService } from '../../fetch-services/user.service';
import { ClassStudent, StudentsSearchByClassRequest } from '../../fetch-services/group.service.types';
import { GroupService } from '../../fetch-services/group.service';
import { OrgGroupDetails } from '../../common/org-group-details';
import { OrgGroupLevel } from '../../common/enums';
import { NoResultsFoundDescription, NoResultsFoundMessage } from '../../common/consts';
import { getReportFilename } from '../../common/utils';
import { StudentsProps } from './students.props';
import './students.scss';

const emptyPage: Paged<Student | ClassStudent> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const Students = (props: StudentsProps) => {
  const { orgGroupLevel } = props;
  const { currentOrg, currentClass } = useSelector((state: RootState) => state.user);
  const [orgGroupDetails, setOrgGroupDetails] = React.useState<OrgGroupDetails>({
    orgGroupLevel: orgGroupLevel,
    orgGroupGuid: '',
    orgGroupName: ''
  });
  const [studentsPagedData, setStudentsPagedData] = React.useState<Paged<Student | ClassStudent>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('LastName');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
  const [showSearchHelp, setShowSearchHelp] = React.useState(false);

  const searchTextRef = useRef('');
  const navigate = useNavigate();

  let studentColumnDetails =
  [
    {
      name: 'Student Name',
      getColumnData: (data: Student | ClassStudent) => `${data.lastName}, ${data.firstName}`,
      alignment: 'left',
      sortProperty: 'LastName',
      onLinkSelect: (data: Student | ClassStudent) => navigate(`/student/${(data).userGuid}`)
    },
    {
      name: 'Username',
      getColumnData: (data: Student | ClassStudent) => orgGroupLevel === OrgGroupLevel.Class ? (data as ClassStudent).userName : (data as Student).loginName,
      sortProperty: orgGroupLevel === OrgGroupLevel.Class ? 'UserName' : 'LoginName',
      alignment: 'left'
    },
    {
      name: 'School',
      getColumnData: (data: Student) => data.schoolName,
      sortProperty: 'SchoolName',
      alignment: 'left'
    },
    {
      name: 'External ID',
      getColumnData: (data: Student) => data.districtAssignedId,
      alignment: 'left'
    },
    {
      name: 'Grade Level',
      getColumnData: (data: Student | ClassStudent) => data.gradeLevelName,
      sortProperty: orgGroupLevel === OrgGroupLevel.Class ? 'gradelevel' : 'GradeLevelId',
      alignment: 'left'
    }
  ];

  if (orgGroupLevel === OrgGroupLevel.School || orgGroupLevel === OrgGroupLevel.Class) {
    studentColumnDetails = studentColumnDetails.filter((column => column.name !== 'School'));
  };

  if (orgGroupLevel === OrgGroupLevel.Class) {
    studentColumnDetails = studentColumnDetails.filter((column => column.name !== 'External ID'));
  }

  let studentsCSVColumns = [
    {
      label: 'Student Last Name',
      id: 'lastName'
    },
    {
      label: 'Student First Name',
      id: 'firstName'
    }
  ];

  if (orgGroupLevel === OrgGroupLevel.District) {
    studentsCSVColumns = [
      ...studentsCSVColumns,
      {
        label: 'Username',
        id: 'loginName'
      },
      {
        label: 'School',
        id: 'schoolName'
      },
      {
        label: 'External ID',
        id: 'districtAssignedId'
      },
      {
        label: 'Grade Level',
        id: 'gradeLevelName'
      }
    ];
  }
  if (orgGroupLevel === OrgGroupLevel.School || orgGroupLevel === OrgGroupLevel.Class) {
    studentsCSVColumns = [
      ...studentsCSVColumns,
      {
        label: 'Username',
        id: 'userName'
      },
      {
        label: 'External ID',
        id: 'districtAssignedId'
      },
      {
        label: 'Grade Level',
        id: 'gradeLevel'
      }
    ];
  }

  const getStudentSearchRequest = () => {
    let request = {
      searchText: searchText,
      sortOrder: sortDirection,
      products: [ProductEnum.IPA],
      page: page,
      pageSize: pageSize
    }
    switch (orgGroupLevel) {
      case OrgGroupLevel.District:
        return {
          ...request,
          sortBy: sortColumn,
          orgGuid: orgGroupDetails.orgGroupGuid,
          grades: []
        } as StudentSearchByDistrictRequest;
      case OrgGroupLevel.School:
        return {
          ...request,
          sortBy: sortColumn,
          organizationGuid: orgGroupDetails.orgGroupGuid,
          filterGradeLevels: ''
        } as StudentSearchBySchoolRequest;
      case OrgGroupLevel.Class:
      default:
        return {
          ...request,
          filterGradeLevels: '',
          sortName: sortColumn,
          classGuid: orgGroupDetails.orgGroupGuid
        } as StudentsSearchByClassRequest;
    }
  };

  const fetchStudents = async () => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
    switch (orgGroupLevel) {
      case OrgGroupLevel.District:
        response = await UserService.getStudentsByDistrict(getStudentSearchRequest() as StudentSearchByDistrictRequest);
        break;
      case OrgGroupLevel.School:
        response = await UserService.getStudentsBySchool(getStudentSearchRequest() as StudentSearchBySchoolRequest);
        break;
      case OrgGroupLevel.Class:
      default:
        response = await GroupService.getStudentsByClass(getStudentSearchRequest() as StudentsSearchByClassRequest);
        break;
    }
    if (response) {
      setStudentsPagedData(response);
      if (!showSearchHelp) {
        setShowSearchHelp(true);
      }
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchStudentsForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
      switch (orgGroupLevel) {
        case OrgGroupLevel.District:
          response = await UserService.getStudentsByDistrictCSV(getStudentSearchRequest() as StudentSearchByDistrictRequest);
          break;
        case OrgGroupLevel.School:
          response = await UserService.getStudentsBySchoolCSV(getStudentSearchRequest() as StudentSearchBySchoolRequest);
          break;
        case OrgGroupLevel.Class:
        default:
          response = await GroupService.getStudentsByClassCSV(getStudentSearchRequest() as StudentsSearchByClassRequest);
          break;
      }
    if (response) {
      csvCallback({rows: response, columns: studentsCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    if (orgGroupDetails.orgGroupGuid) {
      fetchStudents();
    }
  }, [orgGroupDetails.orgGroupGuid, searchText, sortColumn, sortDirection, page, pageSize]);

  useEffect(() => {
    switch (orgGroupLevel) {
      case OrgGroupLevel.Class:
        if (currentClass) {
          setOrgGroupDetails({
            orgGroupLevel: orgGroupLevel,
            orgGroupGuid: currentClass.classGUID,
            orgGroupName: currentClass.classTitle
          });
        }
        break;
      case OrgGroupLevel.School:
      case OrgGroupLevel.District:
        if (currentOrg) {
          setOrgGroupDetails({
            orgGroupLevel: orgGroupLevel,
            orgGroupGuid: currentOrg.orgGuid,
            orgGroupName: currentOrg.name
          });
        }
        break;
      default:
        break;
    }
  }, [orgGroupLevel, currentOrg, currentClass]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setPage(1);
      setSearchText('');
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='students-search'>
      <div className='students-search-header'>
        <SearchSubmit 
          placeHolder='Search Student Name, Username'
          className='students-search-bar'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Student Roster', orgGroupDetails.orgGroupName)}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchStudentsForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={studentColumnDetails}
        tableData={studentsPagedData.values ?? []}
        emptyTableContent={showSearchHelp &&
          <HelpMessage
            message={NoResultsFoundMessage}
            description={NoResultsFoundDescription}
            icon={HelpMessageEnum.NO_RESULTS}
          />
        }
        tableHeight='calc(100% - 54px)'
        minTableHeight='190px'
        includePagination={true}
        totalItemsCount={studentsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='students-table'
      />
    </div>
  );
};

export default Students;
