import React, { useEffect } from 'react';
import store from '../../../redux-store/store';
import { setPageTitle } from '../../../redux-store/slices/app-slice';
import { ScreenerReportProps } from './screener.props';

const Screener = (props: ScreenerReportProps) => {
  const { pageTitleBehaviorSubject } = props;

  useEffect(() => {
    const screenerPageTitleSubscription = pageTitleBehaviorSubject.subscribe((pageTitle) => {
      if (pageTitle) {
        store.dispatch(setPageTitle(pageTitle));
      }
    });

    return () => {
      screenerPageTitleSubscription.unsubscribe();
    }
  }, [pageTitleBehaviorSubject]);

  return <div id="screener"></div>;
};

export default Screener;
