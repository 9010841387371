import { ClassEducator } from '../fetch-services/group.service.types'
import { Organization } from '../fetch-services/org.service.types'
import { Student, UserInfo } from '../fetch-services/user.service.types'
import { ActiveSidebarNavSessionStorageKey, CurrentOrgGuidSessionStorageKey, MainOrgGuidSessionStorageKey } from './consts'
import { Role } from './enums'

export const getReportFilename = (reportType: string, reportOrgName: string) => {
  return reportType + '_' + reportOrgName + '_' +
  (new Date(`${new Date()}`).getUTCMonth() + 1) +
  '_' +
  new Date(`${new Date()}`).getUTCDate() +
  '_' +
  new Date(`${new Date()}`).getUTCFullYear() +
  '_' +
  Date.now()
}

export const getRoleDisplayName = (role: Role) => {
  switch (role) {
    case Role.DistrictAdmin:
      return 'District Administrator'
    case Role.SchoolAdmin:
      return 'School Administrator'
    case Role.Teacher:
      return 'Teacher'
    default:
      return ''
  }
}

export const getEducatorDisplayNames = (educators?: ClassEducator[]) => {
  if (educators) {
    const sortedEducators = [...educators].sort((a: ClassEducator, b: ClassEducator) => a.sequence - b.sequence);
    return sortedEducators.map((educator: ClassEducator) => {
      if (educator.firstName || educator.lastName) {
        return `${educator.lastName ? educator.lastName : '-'}, ${educator.firstName ? educator.firstName : '-'}`;
      } else {
        return '-';
      }
    }).join('; ');
  } else {
    return '';
  }
}

export const getUserDisplayName = (user: UserInfo | Student) => {
  if (user.firstName || user.lastName) {
    return `${user.firstName ? user.firstName : '-'} ${user.lastName ? user.lastName : '-'}`;
  } else {
    return '-';
  }
}

export const parseCurrentOrg = (currentOrg: string | null) => {
  try {
    return currentOrg ? JSON.parse(currentOrg) as Organization : null;
  }
  catch (error) {
    return null;
  }
}

export const clearUserNavSessionStorage = () => {
  sessionStorage.removeItem(MainOrgGuidSessionStorageKey);
  sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
  sessionStorage.removeItem(ActiveSidebarNavSessionStorageKey);
}