import { ReportLinkCardEnum, ProductEnum } from "@wne/mpng-components";
import { Organization } from "../fetch-services/org.service.types";
import { UserInfo } from "../fetch-services/user.service.types";

export type ProductSetting = {
  reports: ReportLinkCardEnum[];
}

const MathELASetting = {
  reports: [ReportLinkCardEnum.DIAGNOSTIC, ReportLinkCardEnum.FORMATIVES]
};

export const ProductSettings: { [key in ProductEnum]: ProductSetting } = {
  [ProductEnum.IPA]: { reports: [] },
  [ProductEnum.IPA_ELA]: MathELASetting,
  [ProductEnum.IPA_Math]: MathELASetting,
  [ProductEnum.IPA_Screener]: { reports: [ReportLinkCardEnum.SCREENER] }
};

export const getProductSettings = (products: ProductEnum[]): ProductSetting => {
  let combinedReports: ReportLinkCardEnum[] = [];
  products.forEach(product => combinedReports = [...combinedReports, ...(ProductSettings[product]?.reports ?? [])]);
  return { reports: Array.from(new Set(combinedReports)) } as ProductSetting;
};

export const getUserProducts = (mainOrg: Organization | null, userInfo: UserInfo | null) => {
  let products: ProductEnum[] = [];
  if (mainOrg) {
    products = (mainOrg.licensedProducts ?? []) as ProductEnum[];
  } else if (userInfo) {
    //If main org is not set, use combined products from user organizations
    products = (userInfo.organizations?.flatMap(org => org.licensedProducts) ?? []) as ProductEnum[];
  }
  return Array.from(new Set(products));
}