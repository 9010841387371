import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import store from '../../redux-store/store';
import { setActivityIndicator, setPageTitle } from '../../redux-store/slices/app-slice';
import { Tabs } from '@wne/mpng-components';
import Students from '../../components/students/students';
import Reports from '../../components/reports/reports';
import Settings from '../../components/settings/settings';
import { OrgGroupLevel } from '../../common/enums';
import { Setting } from '../../components/settings/settings.props';
import { GroupService } from '../../fetch-services/group.service';
import { getEducatorDisplayNames } from '../../common/utils';
import { setCurrentClass } from '../../redux-store/slices/user-slice';
import '../containers.scss';

const Class = () => {
  const { id } = useParams();
  const [settings, setSettings] = useState<Setting[]>([
    { setting: 'Class Name', values: ['-'] },
    { setting: 'External ID', values: ['-'] },
    { setting: 'Teacher(s)', values: ['-'] },
    { setting: 'Academic Session', values: ['-'] }
  ]);

  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await GroupService.getClass(id ?? '');
      store.dispatch(setActivityIndicator(false));
      store.dispatch(setPageTitle(response?.classTitle ?? '-'));
      store.dispatch(setCurrentClass(response));
      setSettings([
        { setting: 'Class Name', values: [response?.classTitle ?? '-'] },
        { setting: 'External ID', values: [response?.districtAssignedId ?? '-'] },
        { setting: 'Teacher(s)', values: [getEducatorDisplayNames(response?.educators) || '-'] },
        { setting: 'Academic Session', values: [response?.academicSessionTitle ?? '-'] }
      ]);
    }
    
    if (id) {
      fetchData();
    } else {
      store.dispatch(setPageTitle('-'));
    }
    
    return () => {
      store.dispatch(setCurrentClass(null));
    }
  }, [id]);
  
  const tabsProps = {
    className: 'asmt-container',
    defaultActiveKey: 'students',
    mountOnEnter: true,
    items: [
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students orgGroupLevel={OrgGroupLevel.Class} />
      },
      {
        title: 'Reports',
        eventKey: 'reports',
        content: <Reports orgGroupLevel={OrgGroupLevel.Class} />
      },
      {
        title: 'Settings',
        eventKey: 'settings',
        content: <Settings settings={settings} />
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default Class;
