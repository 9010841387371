import React, { useEffect } from 'react';
import store from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import AcademicSessions from '../../components/academic-sessions/academic-sessions';
import '../containers.scss';

//Loads the Academic Sessions component from the sidebar
const AcademicSessionsContainer = () => {
  useEffect(() => {
    store.dispatch(setPageTitle('Academic Sessions'));
  }, []);

  return (
    <div className='asmt-container'>
      <AcademicSessions />
    </div>
  );
};

export default AcademicSessionsContainer;
