export enum Role {
  DistrictAdmin = 3,
  SchoolAdmin = 4,
  Teacher = 5,
  Student = 6
}

export enum SidebarLabel {
  Home = 'Home',
  Institutions = 'Institutions',
  District = 'District',
  School = 'School',
  Classes = 'Classes',
  Users = 'Users',
  Students = 'Students',
  Scheduling = 'Scheduling',
  AcademicSessions = 'Academic Sessions',
  Formatives = 'Formatives',
  Settings = 'Settings',
  Reports = 'Reports',
  Help = 'Help'
}

export enum SidebarRoute {
  Home = '/home',
  Institutions = '/institutions',
  District = '/district',
  Classes = '/classes',
  Users = '/users',
  Students = '/students',
  Scheduling = '/scheduling',
  AcademicSessions = '/academicsessions',
  Formatives = '/assignmentbuilder/dashboard',
  Settings = '/settings',
  Reports = '/reports'
}

export enum AppRoute {
  Profile = '/profile',
  School = '/school',
  Class = '/class',
  Diagnostic = '/mypath-assessment-reports',
  Screener = '/screener',
  Base = '/'
}

export enum OrgGroupLevel {
  District = 'district',
  School = 'school',
  Class = 'class'
}
