import React, { useEffect } from 'react';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { ClassCardsProps } from './class-cards.props';
import { Class, ClassesSearchRequest } from '../../fetch-services/group.service.types';
import { UserService } from '../../fetch-services/user.service';
import { GroupService } from '../../fetch-services/group.service';
import { useNavigate } from 'react-router-dom';
import { StudentClassesRequest } from '../../fetch-services/user.service.types';
import { Role } from '../../common/enums';
import { ClassCard, HelpMessage, HelpMessageEnum, ProductEnum } from '@wne/mpng-components';
import { NoClassesCreatedMessage, NoStudentClassesMessage } from '../../common/consts';
import { getEducatorDisplayNames } from '../../common/utils';
import './class-cards.scss';

const ClassCards = (props: ClassCardsProps) => {
  const { displayFor, studentGuid, studentName } = props;
  const [classes, setClasses] = React.useState<Class[]>([]);
  const [showClassesHelpMessage, setShowClassesHelpMessage] = React.useState(false);
  const { userInfo, currentOrg } = useSelector((state: RootState) => state.user);

  const navigate = useNavigate();

  const getNoClassesMessage = () => {
    if (displayFor === Role.Student) {
      return `${studentName ?? '-'}${NoStudentClassesMessage}`;
    } else {
      return NoClassesCreatedMessage;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      let classes: Class[] = [];
      let response = null;
      switch (displayFor) {
        case Role.Student:
          response = await UserService.getStudentClasses({
            organizationGuid: currentOrg?.orgGuid ?? '',
            studentGuid: studentGuid,
            products: [ProductEnum.IPA]
          } as StudentClassesRequest);
          if (response) {
            classes = response;
          }
          break;
        case Role.Teacher:
        default:
          response = await GroupService.getClasses({
            orgGuid: currentOrg?.orgGuid ?? '',
            page: 1,
            pageSize: 0,
            filterSubject: [ProductEnum.IPA]
          } as ClassesSearchRequest);
          if (response) {
            classes = response.values;
          }
          break;
      }
      setClasses(classes);
      setShowClassesHelpMessage(true);
      store.dispatch(setActivityIndicator(false));
    };

    if (currentOrg?.orgGuid && (studentGuid || displayFor !== Role.Student)) {
      fetchData();
    }
  }, [currentOrg, studentGuid]);

  return (
    <>
      {classes.length > 0 &&
        <div className='class-cards-container'>
          {classes.map((classData, index) => (
            <div key={classData.classGUID}>
              {index > 1 && <hr className='card-separator' data-testid='card-separator' />}
              <ClassCard
                key={classData.classGUID}
                title={classData.classTitle}
                teachers={getEducatorDisplayNames(classData.educators)}
                onClick={
                  //disable navigation if teacher is viewing student classes since they may not have access to the class
                  (userInfo?.role === Role.Teacher && displayFor === Role.Student)
                  ? undefined
                  : () => navigate(`/class/${classData.classGUID}`) 
                }
                mpngStyle={false}
              />
            </div>
          ))}
        </div>
      }
      {showClassesHelpMessage && classes.length === 0 &&
        <HelpMessage
          message={getNoClassesMessage()}
          icon={HelpMessageEnum.NO_CLASSES}
        />
      }
    </>
  );
};

export default ClassCards;
