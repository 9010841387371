import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';

const IpaHome = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle(userInfo?.organizations[0].name || ''));
  }, [userInfo]);

  return <div id="ipa-educator-home"></div>;
};

export default IpaHome;
