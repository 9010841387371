import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store, { RootState } from '../../redux-store/store';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import {
  SearchSubmit,
  PagedNavTable,
  MpngExportReport,
  HelpMessage,
  HelpMessageEnum,
  ProductEnum
} from '@wne/mpng-components';
import { Paged } from '../../fetch-services/user.service.types';
import { Class, ClassesSearchRequest } from '../../fetch-services/group.service.types';
import { GroupService } from '../../fetch-services/group.service';
import { NoResultsFoundDescription, NoResultsFoundMessage } from '../../common/consts';
import { getReportFilename, getEducatorDisplayNames } from '../../common/utils';
import { useSelector } from 'react-redux';
import './class-list.scss';

const emptyPage: Paged<Class> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const ClassList = () => {
  const { currentOrg } = useSelector((state: RootState) => state.user);
  const [classesPagedData, setClassesPagedData] = React.useState<Paged<Class>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('classtitle');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
  const [showSearchHelp, setShowSearchHelp] = React.useState(false);

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let classColumnDetails =
  [
    {
      name: 'Class Name',
      getColumnData: (data: Class) => data.classTitle,
      alignment: 'left',
      sortProperty: 'classtitle',
      onLinkSelect: (data: Class) => navigate(`/class/${data.classGUID}`)
    },
    {
      name: 'Teacher',
      getColumnData: (data: Class) => getEducatorDisplayNames(data.educators),
      sortProperty: 'teacher',
      alignment: 'left'
    }
  ];

  const classesCSVColumns = [
    {
      label: 'Class Name',
      id: 'classTitle'
    },
    {
      label: 'Class External ID',
      id: 'districtAssignedId'
    },
    {
      label: 'Teacher Name',
      id: 'teacherName'
    }
  ];

  const getClassesSearchRequest = (): ClassesSearchRequest => {
    const request: ClassesSearchRequest = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: currentOrg?.orgGuid ?? '',
      page: page,
      pageSize: pageSize,
      filterSubject: [ProductEnum.IPA]
    };
    return request;
  };

  const fetchClasses = async () => {
    store.dispatch(setActivityIndicator(true));
    const response = await GroupService.getClasses(getClassesSearchRequest());
    if (response) {
      setClassesPagedData(response);
      if (!showSearchHelp) {
        setShowSearchHelp(true);
      }
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchClassesForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    const response = await GroupService.getClassesCSV(getClassesSearchRequest());
    if (response) {
      const csvClassDetails = response.map((classData: Class) => {
        return {
          classTitle: classData.classTitle,
          districtAssignedId: classData.districtAssignedId,
          teacherName: getEducatorDisplayNames(classData.educators)
        }
      });
      csvCallback({rows: csvClassDetails, columns: classesCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    if (currentOrg?.orgGuid) {
      fetchClasses();
    }
  }, [currentOrg, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setPage(1);
      setSearchText('');
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='class-list-container'>
      <div className='class-list-header'>
        <SearchSubmit 
          placeHolder='Search Class Name or Teacher'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Classes', currentOrg?.name ?? '')}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchClassesForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={classColumnDetails}
        tableData={classesPagedData.values}
        emptyTableContent={showSearchHelp &&
          <HelpMessage
            message={NoResultsFoundMessage}
            description={NoResultsFoundDescription}
            icon={HelpMessageEnum.NO_RESULTS}
          />
        }
        tableHeight='calc(100% - 54px)'
        minTableHeight='190px'
        includePagination={true}
        totalItemsCount={classesPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='classes-table'
      />
    </div>
  );
};

export default ClassList;
