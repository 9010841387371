import { getAccessToken } from './common/get-access-token';
import { fetchWithTimeout } from './common/fetch-with-timeout';
import { AcademicSession, GetChildOrganizationsRequest, Organization, OrganizationSettings } from './org.service.types';
import { Paged } from './user.service.types';

const orgBaseUrl = window.APP_ENV.adminApiUrl;

export const OrgService = {
  getOrganization: async (orgGuid: string): Promise<Organization | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${orgBaseUrl}/v2/organization/${orgGuid}`, {
      headers
    }, `OrgService.getOrganization for ${orgGuid}`);

    return response ? response as Organization : null;
  },
  getChildOrganizations: async (request: GetChildOrganizationsRequest): Promise<Paged<Organization> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${orgBaseUrl}/v2/organization/childorganizations`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `OrgService.getChildOrganizations for ${request.orgGuid}`);
 
    return response ? response as Paged<Organization> : null;
  },
  getAcademicSessions: async (orgGuid: string): Promise<AcademicSession[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${orgBaseUrl}/academicsessionswithguid?organizationGuid=${orgGuid}`, {
      headers
    }, `OrgService.getAcademicSessions for ${orgGuid}`);

    return response ? response as AcademicSession[] : null;
  },
  getSettings: async (orgGuid: string): Promise<OrganizationSettings | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${orgBaseUrl}/v2/organization/settings/${orgGuid}`, {
      headers
    }, `OrgService.getSettings for ${orgGuid}`);

    return response ? response as OrganizationSettings : null;
  }
};
