import { User, UserManager } from 'oidc-client-ts';
import { EnvProps } from '../../../root-config/src/shared/env.props';
import { BehaviorSubject } from 'rxjs';
import { ImlpClientId } from '../common/consts';

declare global {
  interface Window {
    APP_ENV: EnvProps;
  }
}

const config = {
  authority: window.APP_ENV?.authority || '',
  client_id: ImlpClientId,
  redirect_uri: `${
    window.APP_ENV?.mps_ui_assessment_url || ''
  }?path=login-callback`,
  silent_redirect_uri: window.APP_ENV?.mps_ui_assessment_url || '',
  post_logout_redirect_uri: window.APP_ENV?.ulpRedirectUrl || '',
  response_type: 'code',
  scope:
    'Scope.IL.Permissions.Administrator.AllowOrgRead Scope.IL.Permissions.Educator.AllowOrgRead Scope.IL.Permissions Scope.ALA.Services Scope.MPNG.ProgressionStatusInfo Scope.RAD.ClassSummaryDashboard Scope.RAD.UsageReportClasses Scope.RAD.UsageReportStudents Scope.AssessmentScheduler.Api Scope.mptools.AssignmentBuilder Scope.Link.Api openid offline_access Scope.IPA.Reporting.API',
  revokeAccessTokenOnSignout: true,
  staleStateAge: 43200,
  automaticSilentRenew: false
};

const userManager = new UserManager(config);

userManager
  .clearStaleState()
  .then(() => {})
  .catch(() => {});

export const getUser = (): Promise<User | null> => {
  return userManager.getUser();
};

export const login = (): Promise<void> => {
  return userManager.signinRedirect();
};

export const renewToken = (): Promise<User | null> => {
  return userManager.signinSilent();
};

export const logout = (): Promise<void> => {
  return userManager.signoutRedirect();
};

export const signinCallback = (url: string): Promise<void | User> => {
  return userManager.signinCallback(url);
};

// Token refresh using refresh token
export const refreshToken = async () => {
  try {
    const user = await userManager.getUser();
    if (user && user.refresh_token) {
      const refreshedUser: User | null = await userManager.signinSilent();
      return refreshedUser;
    } else {
      console.warn('No refresh token available');
    }
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// Automatically refresh the token before it expires
export const autoRenewToken = (
  accessTokenBehaviorSubject: BehaviorSubject<string>
) => {
  userManager.events.addAccessTokenExpired(async () => {
    console.error('Access token has expired, logging out...');
    localStorage.clear();
    sessionStorage.clear();
    await logout();
  });

  userManager.events.addAccessTokenExpiring(async () => {
    try {
      const refreshedUser = await refreshToken();
      if (refreshedUser) {
        accessTokenBehaviorSubject.next(refreshedUser.access_token);
      }
    } catch (error) {
      console.error('Access token refresh failed:', error);
    }
  });
};
