import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { useParams } from 'react-router-dom';
import { setPageTitle, setActivityIndicator } from '../../redux-store/slices/app-slice';
import { setCurrentOrg } from '../../redux-store/slices/user-slice';
import { OrgGroupLevel } from '../../common/enums';
import { OrgService } from '../../fetch-services/org.service';
import { Tabs } from '@wne/mpng-components';
import Students from '../../components/students/students';
import Educators from '../../components/educators/educators';
import DistrictInstitutions from '../../components/district-institutions/district-institutions';
import Reports from '../../components/reports/reports';
import AcademicSessions from '../../components/academic-sessions/academic-sessions';
import OrgSettings from '../../components/org-settings/org-settings';
import { CurrentOrgGuidSessionStorageKey } from '../../common/consts';
import '../containers.scss';

const District = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  const { id } = useParams();

  useEffect(() => {
    const fetchOrg = async () => {
      //district page can be loaded from the side bar of from the district institutions page
      //If the page is loaded from the district institutions page, we will use the id from the path
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getOrganization(id ?? '');
      store.dispatch(setActivityIndicator(false));
      store.dispatch(setPageTitle(response?.name ?? '-'));
      store.dispatch(setCurrentOrg(response));
      if (response) {
        sessionStorage.setItem(CurrentOrgGuidSessionStorageKey, JSON.stringify(response));
      } else {
        sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
      }
    }

    if (id) {
      fetchOrg();
    } else {
      //if the page is loaded from the side bar, there will be no id in the path and we will use the mainOrg
      store.dispatch(setCurrentOrg(mainOrg));
      sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
      store.dispatch(setPageTitle(mainOrg?.name ?? '-'));
    }
  }, [id, mainOrg]);

  const tabsProps = {
    className: 'asmt-container',
    defaultActiveKey: 'institutions',
    mountOnEnter: true,
    items: [
      {
        title: 'Institutions',
        eventKey: 'institutions',
        content: <DistrictInstitutions />
      },
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students orgGroupLevel={OrgGroupLevel.District} />
      },
      {
        title: 'Educators',
        eventKey: 'educators',
        content: <Educators />
      },
      {
        title: 'Reports',
        eventKey: 'reports',
        content: <Reports orgGroupLevel={OrgGroupLevel.District} />
      },
      {
        title: 'Academic Sessions',
        eventKey: 'academic-sessions',
        content: <AcademicSessions />
      },
      {
        title: 'Settings',
        eventKey: 'settings',
        content: <OrgSettings />
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default District;
