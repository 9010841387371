import React, { useEffect } from 'react';
import { BehaviorSubject } from 'rxjs';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../../redux-store/store';
import { setPageTitle } from '../../../redux-store/slices/app-slice';
import { FormativesType } from '../../../../../root-config/src/shared/formatives.types';

export type FormativesProps = {
  formativesBehaviorSubject?: BehaviorSubject<FormativesType>;
};

const Formatives = (props: FormativesProps) => {
  const { formativesBehaviorSubject } = props;
  const { currentOrg } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle('Formatives Dashboard'));
    if (formativesBehaviorSubject && currentOrg) {
      formativesBehaviorSubject.next({
        schoolId: currentOrg.orgId,
        schoolGuid: currentOrg.orgGuid,
        timeZone: currentOrg.timezone,
        organization: currentOrg,
        crumbs: [
          { path: '/assignmentbuilder/dashboard', name: 'Formatives Dashboard' }
        ]
      });
    }
  }, [formativesBehaviorSubject, currentOrg]);

  return <div id="assignment-builder"></div>;
};

export default Formatives;
