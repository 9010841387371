import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux-store/store';
import { ActivityIndicator } from '@wne/mpng-components';
import Navigation from '../navigation/navigation';
import Formatives from '../mfe-placeholders/formatives/formatives';
import DiagnosticReport from '../mfe-placeholders/diagnostic-report';
import { EducatorUiProps } from '../../../../root-config/src/shared/educator-ui.props';
import Institutions from '../institutions/institutions';
import Profile from '../profile/profile';
import ReportsContainer from '../reports-container/reports-container';
import IpaHome from '../mfe-placeholders/ipa-home';
import AssessmentScheduler from '../mfe-placeholders/assessment-scheduler';
import Home from '../home/home';
import Users from '../users/users';
import District from '../district/district';
import School from '../school/school';
import Classes from '../classes/classes';
import Class from '../class/class';
import StudentsContainer from '../students-container/students-container';
import StudentProfile from '../student-profile/student-profile';
import EducatorProfile from '../educator-profile/educator-profile';
import AcademicSessionsContainer from '../academic-sessions-container/academic-sessions-container';
import OrgSettingsContainer from '../org-settings-container/org-settings-container';
import Screener from '../mfe-placeholders/screener/screener';

const Routing = (props: EducatorUiProps) => {
  const { activityIndicator } = useSelector((state: RootState) => state.app);
  return (
    <>
      {activityIndicator && <ActivityIndicator />}
      <BrowserRouter>
        <Routes>
          <Route element={<Navigation />}>
            <Route path='/' element={<Home />} />
            <Route path='home' element={<IpaHome  />} />
            <Route path='profile' element={<Profile />} />
            <Route path='institutions' element={<Institutions />} />
            <Route path='district/:id' element={<District />} />
            <Route path='district' element={<District />} />
            <Route path='school/:id' element={<School />} />
            <Route path='classes' element={<Classes />} />
            <Route path='class/:id' element={<Class />} />
            <Route path='users' element={<Users />} />
            <Route path='students' element={<StudentsContainer />} />
            <Route path='student/:id' element={<StudentProfile />} />
            <Route path='educator/:id' element={<EducatorProfile />} />
            <Route path='academicsessions' element={<AcademicSessionsContainer />} />
            <Route path='settings' element={<OrgSettingsContainer />} />
            <Route path='reports' element={<ReportsContainer />} />
            <Route
              path='scheduling'
              element={
                <AssessmentScheduler
                  organizationBehaviorSubject={
                    props.organizationBehaviorSubject
                  }
                />
              }
            />
            <Route
              path='assignmentbuilder/*'
              element={
                <Formatives
                  formativesBehaviorSubject={props.formativesBehaviorSubject}
                />
              }
            />
            <Route
              path='mypath-assessment-reports/*'
              element={
                <DiagnosticReport
                  pageTitleBehaviorSubject={props.pageTitleBehaviorSubject}
                  breadCrumbBehaviorSubject={props.breadCrumbBehaviorSubject}
                />
              }
            />
            <Route
              path='screener/*'
              element={
                <Screener
                  pageTitleBehaviorSubject={props.pageTitleBehaviorSubject}
                />
              }
            />
            <Route path='*' element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routing;
