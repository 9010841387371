import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { setCurrentOrg } from '../../redux-store/slices/user-slice';
import {
  SearchSubmit,
  PagedNavTable,
  HelpMessage,
  HelpMessageEnum,
  ProductEnum
} from '@wne/mpng-components';
import { OrgService } from '../../fetch-services/org.service';
import { GetChildOrganizationsRequest, Organization } from '../../fetch-services/org.service.types';
import { Paged } from '../../fetch-services/user.service.types';
import { DistrictInstitutionsProps } from './district-institutions.props';
import { CurrentOrgGuidSessionStorageKey, NoResultsFoundDescription, NoResultsFoundMessage } from '../../common/consts';
import './district-institutions.scss';

const emptyPage: Paged<Organization> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const DistrictInstitutions = (props: DistrictInstitutionsProps) => {
  const { orgCallback, filterProducts = [ProductEnum.IPA] } = props;
  const { currentOrg } = useSelector((state: RootState) => state.user);
  const [organizationsPagedData, setOrganizationsPagedData] = React.useState<Paged<Organization>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('Name');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
  const [showSearchHelp, setShowSearchHelp] = React.useState(false);

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  const handleOrgClick = (data: Organization) => {
    //null current org so page doesn't load with old org before new one is fetched
    store.dispatch(setCurrentOrg(null));
    sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
    navigate(`/${data.orgType.toLowerCase()}/${data.orgGuid}`);
  };

  let districtColumnDetails =
  [
    {
      name: 'Institution Name',
      getColumnData: (data: Organization) => data.name,
      alignment: 'left',
      sortProperty: 'Name',
      onLinkSelect: orgCallback || handleOrgClick
    },
    {
      name: 'Category',
      getColumnData: (data: Organization) => data.orgType,
      sortProperty: 'OrgType',
      alignment: 'left'
    }
  ];

  const getChildOrganizationsRequest = (): GetChildOrganizationsRequest => {
    const request: GetChildOrganizationsRequest = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: currentOrg?.orgGuid ?? '',
      page: page,
      pageSize: pageSize,
      licensedProducts: filterProducts
    };
    return request;
  };

  useEffect(() => {
    const fetchChildOrganizations = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getChildOrganizations(getChildOrganizationsRequest());
      if (response) {
        setOrganizationsPagedData(response);
        if (!showSearchHelp) {
          setShowSearchHelp(true);
        }
      }
      store.dispatch(setActivityIndicator(false));
    };

    if (currentOrg?.orgGuid) {
      fetchChildOrganizations();
    }
  }, [currentOrg, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setSearchText('');
      setPage(1);
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='district-institutions-container'>
      <div className='district-institutions-search'>
        <SearchSubmit 
          placeHolder='Search School or District'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
      </div>
      <PagedNavTable
        columnDetails={districtColumnDetails}
        tableData={organizationsPagedData.values}
        emptyTableContent={showSearchHelp &&
          <HelpMessage
            message={NoResultsFoundMessage}
            description={NoResultsFoundDescription}
            icon={HelpMessageEnum.NO_RESULTS}
          />
        }
        tableHeight='calc(100% - 54px)'
        minTableHeight='190px'
        includePagination={true}
        totalItemsCount={organizationsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='district-institutions-table'
      />
    </div>
  );
};

export default DistrictInstitutions;
