import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setActiveNavItem, setPageTitle } from '../../redux-store/slices/app-slice';
import { Role } from '../../common/enums';
import Settings from '../../components/settings/settings';
import { getRoleDisplayName, getUserDisplayName } from '../../common/utils';
import { Setting } from '../../components/settings/settings.props';
import { ActiveSidebarNavSessionStorageKey } from '../../common/consts';
import '../containers.scss';

const Profile = () => {
  const [profileSettings, setProfileSettings] = useState<Setting[]>([
      { setting: 'Name', values: ['-'] },
      { setting: 'Username/Email', values: ['-'] },
      { setting: 'Associated Schools', values: ['-'] },
      { setting: 'Type', values: ['-'] },
      { setting: 'External ID', values: ['-'] }
    ]);
  const { userInfo } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    store.dispatch(setPageTitle('Profile'));
    store.dispatch(setActiveNavItem(null));
    sessionStorage.removeItem(ActiveSidebarNavSessionStorageKey);
    if (userInfo) {
      const orgSettingTitle = userInfo.role === Role.DistrictAdmin ? 'Associated Districts' : 'Associated Schools';
      setProfileSettings([
        { setting: 'Name', values: [getUserDisplayName(userInfo)] },
        { setting: 'Username/Email', values: [userInfo.username || '-'] },
        { setting: orgSettingTitle, values: userInfo.organizations?.map(org => org.name) || ['-'] },
        { setting: 'Type', values: [getRoleDisplayName(userInfo.role)] },
        { setting: 'External ID', values: [userInfo.districtAssignedId || '-'] }
      ]);
    }
  }, [userInfo]);

  return (
    <div className='asmt-container'>
      <Settings settings={profileSettings} />
    </div>
  );
};
export default Profile;
