import React, { useCallback, useEffect, useState } from 'react';
import { autoRenewToken, logout } from '../../auth-service/auth-service';
import store from '../../redux-store/store';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import Routing from '../routing/routing';
import useOidcAuthentication from '../../hooks/use-oidc-authentication';
import { UserService } from '../../fetch-services/user.service';
import { setUser } from '../../redux-store/slices/user-slice';
import { EducatorUiProps } from '../../../../root-config/src/shared/educator-ui.props';
import { initializePendo } from './pendo-initializer';
import { ActivityIndicator, AlertToast, PriorityEnum, ProductEnum } from '@wne/mpng-components';
import { Organization } from '../../fetch-services/org.service.types';
import { DisplayErrorMessage } from '../../common/consts';
import { clearUserNavSessionStorage } from '../../common/utils';

interface ImlpJwtPayload extends JwtPayload {
  tenant_user_id: string;
}

const App = (props: EducatorUiProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [showAuthAlert, setShowAuthAlert] = useState(false);
  const { accessTokenBehaviorSubject, userBehaviorSubject } = props;
  const authState = useOidcAuthentication();

  if (authState?.user) {
    autoRenewToken(accessTokenBehaviorSubject!);
    accessTokenBehaviorSubject!.next(authState?.user.access_token);
  }

  const runInitializePendo = useCallback((pendoUserData) => {
    if (pendoUserData) {
      initializePendo(pendoUserData);
    }
  }, []);

  const filterOrgsByProductAccess = (organizations: Organization[]) => {
    return organizations.filter((org) => {
      return org.licensedProducts.some((product) => product as ProductEnum === ProductEnum.IPA);
    });
  };

  const handleLogout = async () => {
    clearUserNavSessionStorage();
    await logout();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${window.APP_ENV.ulpRedirectUrl}`;
  };

  useEffect(() => {
    const getUserInfo = async (userGuid: string) => {
      const userInfo = await UserService.getUserInfo(userGuid);
      const pendoUserData = await UserService.getPendoUserData(userGuid);
      if (userInfo) {
        userInfo.organizations = filterOrgsByProductAccess(userInfo.organizations);
        if (userInfo.organizations.length > 0) {
          store.dispatch(setUser(userInfo));
          userBehaviorSubject.next(userInfo);
          runInitializePendo(pendoUserData);
          setIsLoading(false);
        } else {
          console.error("User has no organizations with access to I+Assessment")
          setShowAuthAlert(true);
        }
      } else {
        console.error(`Error fetching user info for userGuid: ${userGuid}`);
        setShowAuthAlert(true);
      }
    };

    if (authState?.error) {
      console.error(`Authentication error: ${authState.error}`);
      setShowAuthAlert(true);
    } else if (authState?.user?.access_token) {
      const userGuid = jwtDecode<ImlpJwtPayload>(
        authState?.user?.access_token
      ).tenant_user_id;
      getUserInfo(userGuid);
    }
  }, [authState?.user?.access_token, authState?.error, runInitializePendo]);

  return (
    <div>
      {!showAuthAlert && (
        isLoading ? <ActivityIndicator /> : <Routing {...props} />
      )}
      <AlertToast
        message={DisplayErrorMessage}
        show={showAuthAlert}
        onClose={() => handleLogout()}
        priority={PriorityEnum.WARNING}
        timeout={3000}
      />
    </div>
  );
};

export default App;
