import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setCurrentOrg, setMainOrg } from '../../redux-store/slices/user-slice';
import Header from '../../components/header/header';
import Sidebar from '../../components/sidebar/sidebar';
import { AppRoute, OrgGroupLevel, Role, SidebarRoute } from '../../common/enums';
import { CurrentOrgGuidSessionStorageKey, MainOrgGuidSessionStorageKey } from '../../common/consts';
import { Organization } from '../../fetch-services/org.service.types';
import { parseCurrentOrg } from '../../common/utils';

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo, mainOrg, currentOrg } = useSelector((state: RootState) => state.user);
  const [height, setHeight] = useState('');

  const multiOrgPages: string[] = [
    SidebarRoute.Institutions,
    SidebarRoute.Home,
    AppRoute.Profile,
    AppRoute.Base
  ];

  const mfePages: string[] = [
    SidebarRoute.Scheduling,
    SidebarRoute.Home,
    SidebarRoute.Formatives.split('/')[1],
    AppRoute.Diagnostic,
    AppRoute.Screener
  ];

  useEffect(() => {
    const path = location.pathname;
    let redirectPath = null;
    let nextCurrentOrg = currentOrg;

    if (!mainOrg && userInfo?.organizations) {
      // Most common case, set mainOrg and currentOrg if user has only one org
      if (userInfo.organizations.length === 1) {
        store.dispatch(setMainOrg(userInfo.organizations[0]));
        // check sessionStorage for currentOrg
        nextCurrentOrg = parseCurrentOrg(sessionStorage.getItem(CurrentOrgGuidSessionStorageKey)) ?? userInfo.organizations[0];
        store.dispatch(setCurrentOrg(nextCurrentOrg));
      }
      // If a user has multiple orgs check sessionStorage for mainOrgGuid
      // If not, redirect to institutions page for org selection if on a non-multiOrgPage 
      // The institutions page will set mainOrg and currentOrg and redirect back to the original page
      if (userInfo.organizations.length > 1) {
        const org = userInfo.organizations.find((org: Organization) => {
          return org.orgGuid === sessionStorage.getItem(MainOrgGuidSessionStorageKey);
        });
        if (org) {
          store.dispatch(setMainOrg(org));
          // check sessionStorage for currentOrg
          nextCurrentOrg = parseCurrentOrg(sessionStorage.getItem(CurrentOrgGuidSessionStorageKey)) ?? org;
          store.dispatch(setCurrentOrg(nextCurrentOrg));
        } else if (!multiOrgPages.includes(path)) {
          redirectPath = `${SidebarRoute.Institutions}?link=${path}`;
        }
      }
    }

    // If a user is a district admin navigating to the formatives page with a district current org or no current org),
    // then redirect to institutions page for org selection 
    // The institutions page will set the currentOrg and redirect back to the original page
    if (userInfo?.role === Role.DistrictAdmin && path === SidebarRoute.Formatives &&
        (!nextCurrentOrg || nextCurrentOrg?.orgType.toLowerCase() === OrgGroupLevel.District)) {
      redirectPath = `${SidebarRoute.Institutions}?link=${path}`;
    }

    if(redirectPath || !mfePages.some(mfePage => path.includes(mfePage))) {
      setHeight('calc(100vh - 60px)');
    } else {
      setHeight('');
    }

    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [location, userInfo, mainOrg, currentOrg]);

  return (
    <>
      <Header />
      <Sidebar />
      <div
        style={{
          marginLeft: '87px',
          marginTop: '60px',
          position: 'relative',
          height: height,
          zIndex: 1
        }}
        data-testid='navigation-container'
      >
        <Outlet />
      </div>
    </>
  );
};

export default Navigation;
