export const fetchWithTimeout = async (
  route: string,
  options: RequestInit,
  errorMessageDetails: string,
  timeoutLength = 30000
) => {
  try {
    const response = await fetch(route, {
      ...options,
      signal: AbortSignal.timeout(timeoutLength)
    });
    if (response.ok) {
      return await response.json();
    } else {
      console.error(`${errorMessageDetails} response status: ${response.status}`);
      return null;
    }
  } catch (error) {
    if (error instanceof DOMException && error.name === 'TimeoutError') {
      console.error(`Fetch request to ${route} timed out after ${timeoutLength}ms`);
    } else {
      console.error(`Fetch request to ${route} failed:`, error);
    }
    return null;
  }
};