import { getAccessToken } from './common/get-access-token';
import { fetchWithTimeout } from './common/fetch-with-timeout';
import { Class } from './group.service.types';
import { PendoUserData, Student, Educator, EducatorCSV, Paged, UserInfo, StudentSearchByDistrictRequest, StudentSearchBySchoolRequest, EducatorSearchRequest, StudentCSV, StudentClassesRequest } from './user.service.types';

const userBaseUrl = window.APP_ENV.userApiUrl;

export const UserService = {
  getUserInfo: async (userGuid: string): Promise<UserInfo | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${userBaseUrl}/v2/user/${userGuid}`, {
      headers
    }, `UserService.getUserInfo for ${userGuid}`);

    return response ? response as UserInfo : null;
  },
  getPendoUserData: async (userGuid: string): Promise<PendoUserData | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${userBaseUrl}/api/geteducatorpendometadatabyguid?userguId=${userGuid}`, {
      headers
    }, `UserService.getPendoUserData for ${userGuid}`);

    return response ? response as PendoUserData : null;
  },
  getStudent: async (userGuid: string): Promise<Student | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${userBaseUrl}/student/detailswithguid/${userGuid}`, {
      headers
    }, `UserService.getStudentDetails for ${userGuid}`);

    return response ? response as Student : null;
  },
  getStudentClasses: async (request: StudentClassesRequest): Promise<Class[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/student/enrolledclasses`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getStudentClasses for ${request.studentGuid}`);

    return response ? response as Class[] : null;
  },
  getStudentsByDistrict: async (request: StudentSearchByDistrictRequest): Promise<Paged<Student> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/v2/user/students/bydistrict`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getStudentsByDistrict for ${request.orgGuid}`);

    return response ? response as Paged<Student> : null;
  },
  getStudentsByDistrictCSV: async (request: StudentSearchByDistrictRequest): Promise<Student[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/v2/user/students/bydistrict-csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getStudentsByDistrict for ${request.orgGuid}`);

    return response ? response as Student[] : null;
  },
  getStudentsBySchool: async (request: StudentSearchBySchoolRequest): Promise<Paged<Student> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/api/user/getstudentlistwithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getStudentsBySchool for ${request.organizationGuid}`);

    return response ? response as Paged<Student> : null;
  },
  getStudentsBySchoolCSV: async (request: StudentSearchBySchoolRequest): Promise<StudentCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/school/students/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getStudentsByDistrict for ${request.organizationGuid}`);

    return response ? response as StudentCSV[] : null;
  },
  getEducatorsByDistrict: async (request: EducatorSearchRequest): Promise<Paged<Educator> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/api/user/getdauserswithguid?organizationGuid=${request.orgGuid}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getEducatorsByDistrict for ${request.orgGuid}`);

    return response ? response as Paged<Educator> : null;
  },
  getEducatorsByDistrictCSV: async (request: EducatorSearchRequest): Promise<EducatorCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/district/educators/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getEducatorsByDistrictCSV for ${request.orgGuid}`);

    return response ? response as EducatorCSV[] : null;
  },
  getEducatorsBySchool: async (request: EducatorSearchRequest): Promise<Paged<Educator> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/api/user/geteducatorswithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getEducatorsByDistrict for ${request.orgGuid}`);

    return response ? response as Paged<Educator> : null;
  },
  getEducatorsBySchoolCSV: async (request: EducatorSearchRequest): Promise<EducatorCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${userBaseUrl}/school/educators/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `UserService.getEducatorsByDistrictCSV for ${request.orgGuid}`);

    return response ? response as EducatorCSV[] : null;
  }
};
