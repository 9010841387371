import { SidebarLabel, SidebarRoute } from './enums';
import { IconEnum, IconLibraryEnum } from '@wne/mpng-components';

export type SidebarItem = {
  label: SidebarLabel;
  route: SidebarRoute;
  icon: IconEnum;
  iconLibrary?: IconLibraryEnum;
  selectedIcon?: IconEnum;
};

export const SidebarItems: { [key: string]: SidebarItem } = {
  home: {
    label: SidebarLabel.Home,
    route: SidebarRoute.Home,
    icon: IconEnum.HOUSE
  },
  institutions: {
    label: SidebarLabel.Institutions,
    route: SidebarRoute.Institutions,
    icon: IconEnum.BUILDING
  },
  district: {
    label: SidebarLabel.District,
    route: SidebarRoute.District,
    icon: IconEnum.BUILDING
  },
  classes: {
    label: SidebarLabel.Classes,
    route: SidebarRoute.Classes,
    icon: IconEnum.BOOK_OPEN_COVER
  },
  users: {
    label: SidebarLabel.Users,
    route: SidebarRoute.Users,
    icon: IconEnum.USERS
  },
  students: {
    label: SidebarLabel.Students,
    route: SidebarRoute.Students,
    icon: IconEnum.GRADUATION_CAP
  },
  scheduling: {
    label: SidebarLabel.Scheduling,
    route: SidebarRoute.Scheduling,
    icon: IconEnum.CALENDAR
  },
  academicSessions: {
    label: SidebarLabel.AcademicSessions,
    route: SidebarRoute.AcademicSessions,
    icon: IconEnum.CALENDAR_DAYS
  },
  formatives: {
    label: SidebarLabel.Formatives,
    route: SidebarRoute.Formatives,
    icon: IconEnum.MEMO_CIRCLE_CHECK
  },
  settings: {
    label: SidebarLabel.Settings,
    route: SidebarRoute.Settings,
    icon: IconEnum.GEAR
  },
  reports: {
    label: SidebarLabel.Reports,
    route: SidebarRoute.Reports,
    icon: IconEnum.CHART_LINE,
    iconLibrary: IconLibraryEnum.SOLID,
    selectedIcon: IconEnum.CHART_AREA
  }
};
