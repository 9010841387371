import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@awesome.me/kit-cae4660dd3/icons/classic/solid';
import moment from 'moment';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { PagedNavTable } from '@wne/mpng-components';
import { OrgService } from '../../fetch-services/org.service';
import { AcademicSession } from '../../fetch-services/org.service.types';
import { AcademicSessionsReadonlyMessage, AcademicSessionsResetNote } from '../../common/consts';
import './academic-sessions.scss';

const AcademicSessions = () => {
  const { currentOrg } = useSelector((state: RootState) => state.user);
  const [academicSessionsData, setAcademicSessionsData] = React.useState<AcademicSession[]>([]);
  const [showNoResultsMessage, setShowNoResultsMessage] = React.useState<boolean>(false);
  
  let districtColumnDetails =
  [
    {
      name: 'Academic Session',
      getColumnData: (data: AcademicSession) => data.title,
      alignment: 'left'
    },
    {
      name: 'School Year',
      getColumnData: (data: AcademicSession) => data.schoolYear,
      alignment: 'left'
    },
    {
      name: 'Start Date',
      getColumnData: (data: AcademicSession) => moment(data.startDate).format('LL'),
      alignment: 'left'
    },
    {
      name: 'End Date',
      getColumnData: (data: AcademicSession) => moment(data.endDate).format('LL'),
      alignment: 'left'
    }
  ];

  useEffect(() => {
    const fetchAcademicSessions = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getAcademicSessions(currentOrg?.orgGuid ?? '');
      if (response) {
        setAcademicSessionsData(response);
        setShowNoResultsMessage(response.length === 0);
      }
      store.dispatch(setActivityIndicator(false));
    };

    if (currentOrg?.orgGuid) {
      fetchAcademicSessions();
    }
  }, [currentOrg]);

  return (
    <div className='academic-sessions-container'>
      <div className='academic-sessions-header'>
        <div className={"academic-note"}>
          <b style={{ fontStyle: "italic" }}>Note:</b>{" "}
          {AcademicSessionsReadonlyMessage}
        </div>
        <div className="academic-clock-bar">
          <FontAwesomeIcon className="academic-sessions-clock-icon" icon={faClock} />
          <span className="academic-clock-bar-text-schedule">{AcademicSessionsResetNote}</span>
        </div>
      </div>
      <PagedNavTable
        columnDetails={districtColumnDetails}
        tableData={academicSessionsData}
        emptyTableContent={showNoResultsMessage && 'No academic sessions found'}
        maxTableHeight='calc(100% - 53px)'
        minTableHeight='190px'
        className='academic-sessions-table'
      />
    </div>
  );
};

export default AcademicSessions;
