import React, { useEffect } from 'react';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import Reports from '../../components/reports/reports';
import { OrgGroupLevel } from '../../common/enums';
import '../containers.scss';

const ReportsContainer = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    store.dispatch(setPageTitle('Reports'));
  }, [mainOrg]);

  return (
    <div className='asmt-container'>
      <Reports orgGroupLevel={(mainOrg?.orgType.toLowerCase() ?? '') as OrgGroupLevel} />
    </div>
  );
};

export default ReportsContainer;
