import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReportsProps } from './reports.props';
import { AppRoute, OrgGroupLevel, SidebarLabel, SidebarRoute } from '../../common/enums';
import { IconEnum, ReportLinkCard, ReportLinkCardEnum, ProductEnum } from '@wne/mpng-components';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActiveNavItem, setPageTitle } from '../../redux-store/slices/app-slice';
import { getProductSettings } from '../../common/product-settings';
import './reports.scss';

const Reports = (props: ReportsProps) => {
  const { orgGroupLevel } = props;
  const { currentOrg, currentClass } = useSelector((state: RootState) => state.user);
  const [orgGroupGuid, setOrgGroupGuid] = useState('');
  const [orgGroupName, setOrgGroupName] = useState('');
  const [licensedReports, setLicensedReports] = useState<ReportLinkCardEnum[]>([]);
  const navigate = useNavigate();

  const initializeReportPageTitle = () => {
    if (orgGroupName) {
      store.dispatch(setPageTitle(orgGroupName));
    }
  };

  const handleDiagnosticClick = () => {
    initializeReportPageTitle();
    navigate(`${AppRoute.Diagnostic}/${orgGroupLevel}/${orgGroupGuid}`);
  };

  const handleScreenerClick = () => {
    initializeReportPageTitle();
    navigate(`${AppRoute.Screener}/${orgGroupLevel}/${orgGroupGuid}`);
  };

  const handleFormativesClick = () => {
    store.dispatch(setActiveNavItem(SidebarLabel.Formatives));
    navigate(SidebarRoute.Formatives);
  };

  const showReportCard = (report: ReportLinkCardEnum) => {
    return (
      currentOrg && (currentClass || orgGroupLevel !== OrgGroupLevel.Class) &&
      licensedReports.some((licensedReport) => licensedReport === report)
    );
  };

  useEffect(() => {
    switch (orgGroupLevel) {
      case OrgGroupLevel.Class:
        if (currentClass) {
          setOrgGroupGuid(currentClass.classGUID);
          setOrgGroupName(currentClass.classTitle);
        }
        break;
      case OrgGroupLevel.School:
      case OrgGroupLevel.District:
        if (currentOrg) {
          setOrgGroupGuid(currentOrg.orgGuid);
          setOrgGroupName(currentOrg.name);
        }
        break;
      default:
        break;
    }
    setLicensedReports(getProductSettings((currentOrg?.licensedProducts ?? []) as ProductEnum[]).reports);
  }, [orgGroupLevel, currentOrg, currentClass]);

  return (
    <div className='report-link-cards-container'>
      {showReportCard(ReportLinkCardEnum.DIAGNOSTIC) &&
      <ReportLinkCard
        title={'Diagnostic Report'}
        description={'Lorem ipsum dolor some way of highlighting what the diagnostic report is about and why they should use.'}
        image={ReportLinkCardEnum.DIAGNOSTIC}
        icon={IconEnum.CHEVRON_RIGHT}
        onClick={handleDiagnosticClick}
        mpngStyle={false}
      />}
      {showReportCard(ReportLinkCardEnum.FORMATIVES) &&
      <ReportLinkCard
        title={'Formatives Dashboard'}
        description={'Learn more about how students are performing on quizzes.'}
        image={ReportLinkCardEnum.FORMATIVES}
        icon={IconEnum.LINK}
        onClick={handleFormativesClick}
        mpngStyle={false}
      />}
      {showReportCard(ReportLinkCardEnum.SCREENER) &&
      <ReportLinkCard
        title={'Imagine+ Screener'}
        description={'Lorem ipsum dolor some way of highlighting what the screener report is about and why they should use.'}
        image={ReportLinkCardEnum.SCREENER}
        icon={IconEnum.CHEVRON_RIGHT}
        onClick={handleScreenerClick}
        mpngStyle={false}
      />}
    </div>
  );
};

export default Reports;
