import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { SearchSubmit, PagedNavTable, MpngExportReport, HelpMessage, HelpMessageEnum } from '@wne/mpng-components';
import { UserService } from '../../fetch-services/user.service';
import {
  Paged,
  Educator,
  EducatorSearchRequest
} from '../../fetch-services/user.service.types';
import { NoResultsFoundDescription, NoResultsFoundMessage } from '../../common/consts';
import { OrgGroupLevel } from '../../common/enums';
import { getReportFilename } from '../../common/utils';
import './educators.scss';

const emptyPage: Paged<Educator> = {
  size: 0,
  page: 0,
  pageSize: 0,
  values: []
};

const pageSizeOptions = [50, 100, 250, 500];

const Educators = () => {
  const { currentOrg } = useSelector((state: RootState) => state.user);
  const [educatorsPagedData, setEducatorsPagedData] = React.useState<Paged<Educator>>(emptyPage);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [searchText, setSearchText] = React.useState('');
  const [sortColumn, setSortColumn] = React.useState('lastname');
  const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('asc');
  const [showSearchHelp, setShowSearchHelp] = React.useState(false);

  const searchTextRef = useRef('');

  const navigate = useNavigate();

  let educatorColumnDetails =
  [
    {
      name: 'Educator Name',
      getColumnData: (data: Educator) => `${data.lastName}, ${data.firstName}`,
      alignment: 'left',
      sortProperty: 'lastname',
      onLinkSelect: (data: Educator) => navigate(`/educator/${data.userGuid}`)
    },
    {
      name: 'Username/Email',
      getColumnData: (data: Educator) => data.loginName,
      sortProperty: 'loginname',
      alignment: 'left'
    },
    {
      name: 'Institution',
      getColumnData: (data: Educator) => data.organizations.join(', '),
      sortProperty: 'organizations',
      alignment: 'left'
    },
    {
      name: 'External ID',
      getColumnData: (data: Educator) => data.districtAssignedId,
      alignment: 'left'
    },
    {
      name: 'Educator Type',
      getColumnData: (data: Educator) => data.roleName,
      sortProperty: 'rolename',
      alignment: 'left'
    }
  ];

  if (currentOrg?.orgType.toLowerCase() === OrgGroupLevel.School) {
    educatorColumnDetails = educatorColumnDetails.filter((column) => column.name !== 'Institution');
  };

  const educatorsCSVColumns = [
    {
      label: "Educator Name",
      id: "educatorName"
    },
    {
      label: "Username/Email",
      id: "userName"
    },
    {
      label: "Institution",
      id: "institution"
    },
    {
      label: "External ID",
      id: "districtAssignedId"
    },
    {
      label: "Educator Type",
      id: "educatorType"
    }
  ];

  const getEducatorSearchRequest = (): EducatorSearchRequest => {
    const request = {
      searchText: searchText,
      sortBy: sortColumn,
      sortOrder: sortDirection,
      orgGuid: currentOrg?.orgGuid ?? '',
      page: page,
      pageSize: pageSize,
      filterRoleTypes: ''
    };
    return request;
  };

  const fetchEducators = async () => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
    switch (currentOrg?.orgType.toLowerCase()) {
      case OrgGroupLevel.District:
        response = await UserService.getEducatorsByDistrict(getEducatorSearchRequest());
        break;
      case OrgGroupLevel.School:
      default:
        response = await UserService.getEducatorsBySchool(getEducatorSearchRequest());
    }
    if (response) {
      setEducatorsPagedData(response);
      if (!showSearchHelp) {
        setShowSearchHelp(true);
      }
    }
    store.dispatch(setActivityIndicator(false));
  };

  const fetchEducatorsForCSV = async (csvCallback: (data: any) => void) => {
    store.dispatch(setActivityIndicator(true));
    let response = null;
    switch (currentOrg?.orgType.toLowerCase()) {
      case OrgGroupLevel.District:
        response = await UserService.getEducatorsByDistrictCSV(getEducatorSearchRequest());
        break;
      case OrgGroupLevel.School:
      default:
        response = await UserService.getEducatorsBySchoolCSV(getEducatorSearchRequest());
    }
    if (response) {
      csvCallback({rows: response, columns: educatorsCSVColumns});
    }
    store.dispatch(setActivityIndicator(false));
  };

  useEffect(() => {
    if (currentOrg?.orgGuid) {
      fetchEducators();
    }
  }, [currentOrg, searchText, sortColumn, sortDirection, page, pageSize]);

  const onSearch = () => {
    setSearchText(searchTextRef.current);
    setPage(1);
  };

  const onClearSearch = (fetchResults: boolean) => {
    if (fetchResults) {
      setSearchText('');
      setPage(1);
    }
    searchTextRef.current = '';
  };

  const onSortChange = (sortProperty: string) => {
    if (sortColumn === sortProperty) {
      setSortDirection((prev) => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(sortProperty);
      setSortDirection('asc');
    }
    setPage(1);
  };

  const onPageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(1);
  };

  return (
    <div className='educators-search'>
      <div className='educators-search-header'>
        <SearchSubmit 
          placeHolder='Search Educator Name, Username/Email, External ID'
          className='educators-search-bar'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchTextRef.current = e.target.value}
          onSearch={onSearch}
          onClear={onClearSearch}
        />
        <MpngExportReport
          reportName={getReportFilename('Educators', currentOrg?.name ?? '')}
          includePDF={false}
          mpngStyle={false}
          csvCallback={fetchEducatorsForCSV}
        />
      </div>
      <PagedNavTable
        columnDetails={educatorColumnDetails}
        tableData={educatorsPagedData.values}
        emptyTableContent={showSearchHelp &&
          <HelpMessage
            message={NoResultsFoundMessage}
            description={NoResultsFoundDescription}
            icon={HelpMessageEnum.NO_RESULTS}
          />
        }
        tableHeight='calc(100% - 54px)'
        minTableHeight='190px'
        includePagination={true}
        totalItemsCount={educatorsPagedData.size}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
        sortChange={onSortChange}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        pageNumber={page}
        pageChange={(page: number) => setPage(page)}
        pageSizeChange={onPageSizeChange}
        className='educators-table'
      />
    </div>
  );
};

export default Educators;
