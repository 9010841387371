import React, { useEffect } from 'react';
import {
  ProductSelector,
  Navbar,
  Action,
  IconEnum,
  IconLibraryEnum,
  SizeEnum,
  PositionEnum,
  DirectionEnum,
  OrientationEnum,
  ReportLinkCardEnum,
  ProductEnum
} from '@wne/mpng-components';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setActiveNavItem } from '../../redux-store/slices/app-slice';
import { setCurrentOrg } from '../../redux-store/slices/user-slice';
import { AppRoute, Role, SidebarLabel } from '../../common/enums';
import { SidebarItem, SidebarItems } from '../../common/sidebar-item';
import { ActiveSidebarNavSessionStorageKey, CurrentOrgGuidSessionStorageKey } from '../../common/consts';
import { getProductSettings, getUserProducts } from '../../common/product-settings';
import './sidebar.scss';

const Sidebar = () => {
  const navigate = useNavigate();
  const { userInfo, mainOrg } = useSelector((state: RootState) => state.user);
  const { activeNavItem } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (sessionStorage.getItem(ActiveSidebarNavSessionStorageKey)) {
      store.dispatch(setActiveNavItem(sessionStorage.getItem(ActiveSidebarNavSessionStorageKey) as SidebarLabel));
    } else if (!window.location.pathname.includes(AppRoute.Profile)) {
      // default to home
      store.dispatch(setActiveNavItem(SidebarLabel.Home));
      sessionStorage.setItem(ActiveSidebarNavSessionStorageKey, SidebarLabel.Home);
    }
  }, []);

  const getIconProps = (icon: IconEnum, library: IconLibraryEnum) => ({
    icon,
    size: SizeEnum.MEDIUM,
    textPosition: PositionEnum.BOTTOM,
    squared: true,
    textClassName: 'sidebar-icon-text',
    enhancedClassName: 'sidebar-icon-container',
    library
  });

  const createRouteNavAction = (item: SidebarItem) => {
    const isSelected = activeNavItem === item.label;
    let navIcon = item.icon;
    let navIconLibrary = item.iconLibrary
      ? item.iconLibrary
      : IconLibraryEnum.REGULAR;
    if (isSelected) {
      navIcon = item.selectedIcon ? item.selectedIcon : item.icon;
      navIconLibrary = IconLibraryEnum.SOLID;
    }
    return {
      item: (
        <Action
          callback={() => {
            store.dispatch(setActiveNavItem(item.label));
            // Store in session storage to preserve in case of page refresh
            sessionStorage.setItem(ActiveSidebarNavSessionStorageKey, item.label);
            // Set current org to main org since sidebar tabs always use the mainOrg
            store.dispatch(setCurrentOrg(mainOrg));
            // remove current org from session storage since it will match the main org
            sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
            navigate(item.route);
          }}
          enabled={true}
          text={item.label}
          className={`sidebar-action route ${isSelected ? 'selected' : ''}`}
          icon={getIconProps(navIcon, navIconLibrary)}
        />
      ),
      direction: DirectionEnum.START
    };
  };

  const sidebarDisplayItems = {
    productSelector: {
      item: (
        <ProductSelector
          currentProduct={ProductEnum.IPA}
          //ignore main org products since this should navigate to other products for any school for the user
          licensedProducts={getUserProducts(null, userInfo)}
          env={window.APP_ENV.sentry_env}
          navigationCleanup={() => {
            localStorage.clear();
            sessionStorage.clear();
          }}
          className='asmt-product-selector'
        />
      ),
      direction: DirectionEnum.START
    },
    help: {
      item: (
        <Action
          callback={() => {
            /*open help*/
          }}
          enabled={true}
          text={SidebarLabel.Help}
          className={'sidebar-action'}
          icon={getIconProps(IconEnum.CIRCLE_QUESTION, IconLibraryEnum.SOLID)}
        />
      ),
      direction: DirectionEnum.END
    }
  };

  const roleSidebarItems = [
    sidebarDisplayItems.productSelector,
    createRouteNavAction(SidebarItems.home)
  ];

  if ((userInfo?.organizations.length ?? 0) > 1) {
    roleSidebarItems.push(createRouteNavAction(SidebarItems.institutions));
  }

  if (userInfo?.role === Role.DistrictAdmin) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.district),
      createRouteNavAction(SidebarItems.users),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.reports)
    );
  }

  if (userInfo?.role === Role.SchoolAdmin) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.classes),
      createRouteNavAction(SidebarItems.users),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.reports),
      createRouteNavAction(SidebarItems.academicSessions),
      createRouteNavAction(SidebarItems.settings)
    );
  }

  if (userInfo?.role === Role.Teacher) {
    roleSidebarItems.push(
      createRouteNavAction(SidebarItems.classes),
      createRouteNavAction(SidebarItems.scheduling),
      createRouteNavAction(SidebarItems.students)
    );
  }

  roleSidebarItems.push(sidebarDisplayItems.help);

  if (getProductSettings((mainOrg?.licensedProducts ?? []) as ProductEnum[]).reports.includes(ReportLinkCardEnum.FORMATIVES)) {
    const formativesIndex = roleSidebarItems.findIndex((item) => {
      return item?.item?.props?.text === (userInfo?.role === Role.Teacher ? SidebarLabel.Help : SidebarLabel.Reports);
    });
    if (formativesIndex != -1) {
      roleSidebarItems.splice(formativesIndex, 0, createRouteNavAction(SidebarItems.formatives));
    }
  }

  return (
    <Navbar
      items={roleSidebarItems}
      orientation={OrientationEnum.VERTICAL}
      fixed={PositionEnum.LEFT}
      className="iplus-assessment-sidebar"
      sectionsClassName="sidebar-sections"
      gap="2px"
    />
  );
};
export default Sidebar;
