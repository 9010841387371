import React, { useEffect, useState } from 'react';
import store, { RootState } from '../../redux-store/store';
import { useSelector } from 'react-redux';
import { setActivityIndicator } from '../../redux-store/slices/app-slice';
import { OrgService } from '../../fetch-services/org.service';
import { OrganizationSettings } from '../../fetch-services/org.service.types';
import Settings from '../settings/settings';
import { Setting } from '../settings/settings.props';

const OrgSettings = () => {
  const { currentOrg } = useSelector((state: RootState) => state.user);
  const [settings, setSettings] = useState<Setting[]>([
    { setting: 'Site Code', values: ['-'] },
    { setting: 'External Institution ID', values: ['-'] },
    { setting: 'Category', values: [currentOrg?.orgType ?? '-'] },
    { setting: 'State', values: [currentOrg?.state ?? '-'] },
    { setting: 'Time Zone', values: ['-'] }
  ]);

  const getDisplayTimezone = (settings: OrganizationSettings) => {
    if (settings.timeZoneName && settings.utcTime) {
      return `(UTC ${settings.utcTime}) ${settings.timeZoneName}`;
    } else if (settings.utcTime) {
      return `(UTC ${settings.utcTime})`;
    } else {
      return settings.timeZoneName || '-';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getSettings(currentOrg?.orgGuid ?? '');
      if (response) {
        setSettings([
          { setting: 'Site Code', values: [response.siteCode || '-'] },
          { setting: 'External Institution ID', values: [response.externalId || '-'] },
          { setting: 'Category', values: [currentOrg?.orgType ?? '-'] },
          { setting: 'State', values: [currentOrg?.state ?? '-'] },
          { setting: 'Time Zone', values: [getDisplayTimezone(response)] }
        ]);
      }
      store.dispatch(setActivityIndicator(false));
    }
    
    if (currentOrg?.orgGuid) {
      fetchData();
    }
  }, [currentOrg]);

  return (
    <Settings settings={settings} />
  );
}

export default OrgSettings;
