import React, { useEffect } from 'react';
import store from '../../redux-store/store';
import { useParams } from 'react-router-dom';
import { setPageTitle, setActivityIndicator } from '../../redux-store/slices/app-slice';
import { setCurrentOrg } from '../../redux-store/slices/user-slice';
import { OrgGroupLevel } from '../../common/enums';
import { OrgService } from '../../fetch-services/org.service';
import { Tabs } from '@wne/mpng-components';
import ClassList from '../../components/class-list/class-list';
import Students from '../../components/students/students';
import Educators from '../../components/educators/educators';
import Reports from '../../components/reports/reports';
import AcademicSessions from '../../components/academic-sessions/academic-sessions';
import OrgSettings from '../../components/org-settings/org-settings';
import { CurrentOrgGuidSessionStorageKey } from '../../common/consts';
import '../containers.scss';

const School = () => {
  const { id } = useParams();

  useEffect(() => {
    const fetchOrg = async () => {
      store.dispatch(setActivityIndicator(true));
      const response = await OrgService.getOrganization(id ?? '');
      store.dispatch(setActivityIndicator(false));
      store.dispatch(setCurrentOrg(response));
      store.dispatch(setPageTitle(response?.name ?? '-'));
      if (response) {
        sessionStorage.setItem(CurrentOrgGuidSessionStorageKey, JSON.stringify(response));
      } else {
        sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
      }
    }

    if (id) {
      fetchOrg();
    } else {
      store.dispatch(setCurrentOrg(null));
      sessionStorage.removeItem(CurrentOrgGuidSessionStorageKey);
      store.dispatch(setPageTitle('-'));
    }
  }, [id]);

  const tabsProps = {
    className: 'asmt-container',
    defaultActiveKey: 'classes',
    mountOnEnter: true,
    items: [
      {
        title: 'Classes',
        eventKey: 'classes',
        content: <ClassList />
      },
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students orgGroupLevel={OrgGroupLevel.School} />
      },
      {
        title: 'Educators',
        eventKey: 'educators',
        content: <Educators />
      },
      {
        title: 'Reports',
        eventKey: 'reports',
        content: <Reports orgGroupLevel={OrgGroupLevel.School} />
      },
      {
        title: 'Academic Sessions',
        eventKey: 'academic-sessions',
        content: <AcademicSessions />
      },
      {
        title: 'Settings',
        eventKey: 'settings',
        content: <OrgSettings />
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default School;
