import { getAccessToken } from './common/get-access-token';
import { fetchWithTimeout } from './common/fetch-with-timeout';
import { Class, ClassesSearchRequest, ClassStudent, StudentsSearchByClassRequest } from './group.service.types';
import { Paged, StudentCSV } from './user.service.types';

const groupBaseUrl = window.APP_ENV.lmsApiUrl;

export const GroupService = {
  getClasses: async (request: ClassesSearchRequest): Promise<Paged<Class> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${groupBaseUrl}/v2/class/byuser`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `GroupService.getClasses for ${request.orgGuid}`);

    return response ? response as Paged<Class> : null;
  },
  getClassesCSV: async (request: ClassesSearchRequest): Promise<Class[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${groupBaseUrl}/v2/class/byuser-csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `GroupService.getClassesCSV for ${request.orgGuid}`);

    return response ? response as Class[] : null;
  },
  getClass: async (classGuid: string): Promise<Class | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}` };
    const response = await fetchWithTimeout(`${groupBaseUrl}/class/detailswithguid?classGuid=${classGuid}`, {
      headers
    }, `GroupService.getClass for ${classGuid}`);

    return response ? response as Class : null;
  },
  getStudentsByClass: async (request: StudentsSearchByClassRequest): Promise<Paged<ClassStudent> | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${groupBaseUrl}/class/studentsenrolledwithguid`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `GroupService.getStudentsByClass for ${request.classGuid}`);

    return response ? response as Paged<ClassStudent> : null;
  },
  getStudentsByClassCSV: async (request: StudentsSearchByClassRequest): Promise<StudentCSV[] | null> => {
    const accessToken = getAccessToken();
    const headers = { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'application/json' };
    const response = await fetchWithTimeout(`${groupBaseUrl}/class/students/csv`, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    }, `GroupService.getStudentsByClassCSV for ${request.classGuid}`);

    return response ? response as StudentCSV[] : null;
  }
};
