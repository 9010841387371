import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { Role } from '../../common/enums';
import ClassList from '../../components/class-list/class-list';
import ClassCards from '../../components/class-cards/class-cards';
import '../containers.scss';

const Classes = () => {
  const { userInfo } = useSelector((state: RootState) => state.user);
  
  useEffect(() => {
    store.dispatch(setPageTitle('Classes'));
  }, []);

  return (
    <div className='asmt-container'>
      {userInfo?.role === Role.Teacher ? (
        <ClassCards displayFor={Role.Teacher} />
      ) : (
        <ClassList />
      )}
    </div>
  );
};

export default Classes;
