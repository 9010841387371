import { PendoUserData } from '../../fetch-services/user.service.types';

declare global {
  interface Window {
    pendo: {
      initialize: (config: any) => void;
    };
  }
}

export const initializePendo = (pendoUserData: PendoUserData) => {
  if (window.pendo && window.pendo.initialize) {
    window.pendo.initialize({
      visitor: {
        id: pendoUserData.userGuid,
        email: pendoUserData.email,
        role: pendoUserData.roleName
      },
      account: {
        id: pendoUserData.organizationDetails[0].institutionGuid,
        siteCode: pendoUserData.siteOrganizationDetails.siteCode,
        siteInstitutionName:
          pendoUserData.siteOrganizationDetails.siteInstitutionName,
        siteInstitutionId:
          pendoUserData.siteOrganizationDetails.siteInstitutionGuid,
        siteInstitutionState:
          pendoUserData.siteOrganizationDetails.siteInstitutionState,
        siteInstitutionCategory:
          pendoUserData.siteOrganizationDetails.siteInstitutionOrgType,
        siteInstitutionType:
          pendoUserData.siteOrganizationDetails.siteInstitutionAlaType,
        parentInstitutionName:
          pendoUserData.organizationDetails[0].parentInstitutionName,
        parentInstitutionId:
          pendoUserData.organizationDetails[0].parentInstitutionGuid,
        parentInstitutionState:
          pendoUserData.organizationDetails[0].parentInstitutionState,
        parentInstitutionCategory:
          pendoUserData.organizationDetails[0].parentInstitutionOrgType,
        parentInstitutionType:
          pendoUserData.organizationDetails[0].parentInstitutionAlaType,
        userInstitutionCategory: pendoUserData.organizationDetails
          .map((org) => org.institutionOrgType)
          .join(','),
        userInstitutionType: pendoUserData.organizationDetails
          .map((org) => org.institutionAlaType)
          .join(','),
        userInstitutionName: pendoUserData.organizationDetails
          .map((org) => org.institutionName)
          .join(','),
        userInstitutionId: pendoUserData.organizationDetails
          .map((org) => org.institutionGuid)
          .join(','),
        userInstitutionState: pendoUserData.organizationDetails
          .map((org) => org.institutionState)
          .join(','),
        assessmentProviders: pendoUserData.productsLicensed?.length
          ? pendoUserData.productsLicensed
              .filter((product) => product.includes('IPA'))
              .join(',')
          : '',
        productsLicensed: pendoUserData.productsLicensed?.length
          ? pendoUserData.productsLicensed
          : 'none',
        academicSessions: pendoUserData.academicSessionsResponse
          .flat()
          .map(
            (aca) =>
              `${aca.title} startDate ${aca.startDateFormat.replaceAll(
                '/',
                '-'
              )} endDate ${aca.endDateFormat.replaceAll('/', '-')}`
          )
          .join(',')
      }
    });
  }
};
