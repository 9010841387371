import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../redux-store/store';
import { setPageTitle } from '../../redux-store/slices/app-slice';
import { Tabs } from '@wne/mpng-components';
import Students from '../../components/students/students';
import Educators from '../../components/educators/educators';
import { OrgGroupLevel } from '../../common/enums';
import '../containers.scss';

const Users = () => {
  const { mainOrg } = useSelector((state: RootState) => state.user);
  useEffect(() => {
    store.dispatch(setPageTitle('Users'));
  }, [mainOrg]);

  const tabsProps = {
    className: 'asmt-container',
    defaultActiveKey: 'students',
    mountOnEnter: true,
    items: [
      {
        title: 'Students',
        eventKey: 'students',
        content: <Students orgGroupLevel={(mainOrg?.orgType.toLowerCase() ?? '') as OrgGroupLevel} />
      },
      {
        title: 'Educators',
        eventKey: 'educators',
        content: <Educators />
      }
    ]
  };

  return (
    <Tabs {...tabsProps} />
  );
};

export default Users;
