import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '../../fetch-services/user.service.types';
import { Organization } from '../../fetch-services/org.service.types';
import { Class } from '../../fetch-services/group.service.types';

type UserState = {
  userInfo: UserInfo | null;
  mainOrg: Organization | null;
  currentOrg: Organization | null;
  currentClass: Class | null;
};

const initialState: UserState = {
  userInfo: null,
  mainOrg: null,
  currentOrg: null,
  currentClass: null
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserInfo>) {
      state.userInfo = action.payload;
    },
    setMainOrg(state, action: PayloadAction<Organization | null>) {
      state.mainOrg = action.payload;
    },
    setCurrentOrg(state, action: PayloadAction<Organization | null>) {
      state.currentOrg = action.payload;
    },
    setCurrentClass(state, action: PayloadAction<Class | null>) {
      state.currentClass = action.payload;
    }
  }
});

export const { setUser, setMainOrg, setCurrentOrg, setCurrentClass } = userSlice.actions;

export default userSlice.reducer;
