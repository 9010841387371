import React from "react";

export const ImlpClientId = 'Client.ALA.MPPUser';
export const AcademicSessionsReadonlyMessage = 'The Reset Date will be set at the District level. Please reach out to your Customer Success Manager with any questions or to request a different reset date.';
export const AcademicSessionsResetNote = 'School reset automatically occurs at 5:00 PM Mountain Standard Time (MST) on the reset date.';
export const MainOrgGuidSessionStorageKey = 'mainOrgGuid';
export const CurrentOrgGuidSessionStorageKey = 'currentOrgGuid';
export const ActiveSidebarNavSessionStorageKey = 'activeSidebarNav';
export const DisplayErrorMessage = 'Something went wrong! Please contact Support if issue persists';
export const NoResultsFoundMessage = 'No Results Found';
export const NoResultsFoundDescription = (
  <>
    Try modifying the search terms or use the{" "}
    <a href="https://help.edgenuitymypath.com/hc/en-us/articles/4412008995479" target="_blank">
      tips
    </a>{" "}
    in this Help Center article.
  </>
);
export const NoStudentClassesMessage = ' is not enrolled in any Imagine+ Assessment classes';
export const NoClassesCreatedMessage = 'No Imagine+ Assessment Classes Created';